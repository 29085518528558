import { ClickedUrlHandler, GetIsClickedUrlHandled } from './types';

let handler: ClickedUrlHandler = () => true;

export const getIsClickedUrlHandled: GetIsClickedUrlHandled = (url?: string) =>
  typeof url === 'string' && handler(url) === false;

export default function handleClickedUrl(customHandler: ClickedUrlHandler) {
  handler = customHandler;
}
